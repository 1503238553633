import { Box, Stack, Typography } from '@mui/material'
import BrfSelectedItems from '../../Components/BrfISelectedtems/BrfSelectedItems'
import SearchBRFBar from '../../Components/SearchBrfBar/SearchBRFBar'
import Checkout from '../../Components/Checkout/Checkout'

function BrfSearch() {

  return (
    <Stack className="App" spacing={1}>
      <Typography variant="h4" color="black" align="left" paddingTop="10px">
        Sök BRF
      </Typography>
      <Box sx={{ height: 50, maxWidth: '100%' }} /> 
      <SearchBRFBar />
      <BrfSelectedItems />
      <Checkout />
    </Stack>
  )
}

export default BrfSearch;
