import './SearchBRFBar.css'
import TextField from '@mui/material/TextField'
import { Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import SalmonButton from '../Button/SalmonButton'
import { useState, useEffect } from 'react'
import useSharedSelectedBrfItemsState from '../util/brfSelectedItemsState'

const OPTIONS_LIMIT = 7
const defaultFilterOptions = createFilterOptions()

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT)
}

function SearchBRFBar() {

  const [brfName, setBrfName] = useState('')
  // This state represents the value displayed in the text box
  const [inputValue, setInputValue] = useState('')
  const [items, setItems] = useSharedSelectedBrfItemsState()
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const brfbo_app_url = process.env.REACT_APP_BRFBO_BASE_URL

  useEffect(() => {
    // Fetch data from the external API with custom headers
    fetch(`${brfbo_app_url}/api/v1/brf/search?q=${encodeURIComponent(inputValue)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [inputValue]);

  if (loading) {
    return <Typography>Searching...</Typography>;
  }

  if (error) {
    return <Typography>Error loading data: {error.message}</Typography>;
  }
  const handleBrfChange = (event, selectedValue) => {
    setBrfName(selectedValue)
  }
  const handleInputValueChange = (event, newInputValue) => {
    setInputValue(newInputValue)
  }

  const handleButtonClick = () => {
    // Check if the selected item is on the list already
    if (inputValue == '' || items.indexOf(inputValue) > -1) {
      return
    }

    setItems([...items, inputValue])

    // clear the input field when the item is added to the list
    setInputValue('')
  }

  return (
    <Stack>
      <Stack direction="row" spacing={0.4} sx={{ width: 300 }}>
        <Autocomplete
          freeSolo
          id="search-brf-input"
          filterOptions={filterOptions}
          disableClearable
          options={data.map((option) => option.name)}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={brfName}
          inputValue={inputValue}
          onChange={handleBrfChange}
          onInputChange={handleInputValueChange}
          renderInput={(params) => (
            <Box sx={{ width: 520, maxWidth: '100%' }}>
              <TextField
                {...params}
                label="Sök BRF"
                slotProps={{
                  input: {
                    ...params.InputProps,
                    type: 'search',
                  },
                }}
              />
            </Box>
          )}
        />
        <SalmonButton text='Sök' onClick={handleButtonClick}/>
      </Stack>
      <Box sx={{ width: 520, height: 130, maxWidth: '100%' }} /> 
    </Stack>
  )
}

export default SearchBRFBar
